export const hamburger = () => {
  const menu = document.getElementById("menu");
  const hamburgerBtn = document.getElementById("hamburger-btn");
  const menuCloseBtn = document.getElementById("menu-close-btn");

  // メニューOPEN
  hamburgerBtn.addEventListener("click", () => {
    menu.classList.add("is-active");
  });

  // メニューCLOSE
  menuCloseBtn.addEventListener("click", () => {
    menu.classList.remove("is-active");
  });
};
