export const toc = () => {
  const h2Tags = document.querySelectorAll(".single-case__user-content h2");
  const tocEl = document.getElementById("toc");

  h2Tags.forEach((tag, i) => {
    // h2にidつける
    tag.id = `anchor-${i + 1}`;

    // 目次
    const tagText = tag.innerText;
    const li = document.createElement("li");
    li.innerHTML = `
      <a href="#anchor-${i + 1}">${i + 1}　${tagText}</a>
    `;
    tocEl.appendChild(li);
  });
};
