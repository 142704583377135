import { slideDown, slideUp, slideToggle } from "./slideToggle";

export const accordion = () => {
  // アコーディオンを全て取得
  const accordions = document.querySelectorAll(".js-accordion");
  // 取得したアコーディオンをArrayに変換(IE対策)
  const accordionsArr = Array.prototype.slice.call(accordions);

  accordionsArr.forEach((accordion) => {
    // Triggerを全て取得
    const accordionTriggers = accordion.querySelectorAll(".js-accordion-trigger");
    // TriggerをArrayに変換(IE対策)
    const accordionTriggersArr = Array.prototype.slice.call(accordionTriggers);

    accordionTriggersArr.forEach((trigger) => {
      // Triggerにクリックイベントを付与
      trigger.addEventListener("click", () => {
        // '.is-active'クラスを付与or削除
        trigger.classList.toggle("is-active");
        // 開閉させる要素を取得
        const content = trigger.querySelector(".faq-acc__item-content");
        // 要素を展開or閉じる
        slideToggle(content);
      });
    });
  });
};
