import { hamburger } from "./functions/hamburger";
import { accordion } from "./functions/accordion";
import { anchorScroll } from "./functions/anchorScroll";
import { mwwp } from "./functions/mwwp";
import { toc } from "./functions/toc";

window.addEventListener("DOMContentLoaded", () => {
  hamburger();
  accordion();
  mwwp();
  toc();
  anchorScroll();
});
